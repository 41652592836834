import React, { Component } from 'react';

import Header from '../components/layout/Header';
import Footer from '../components/layout/Footer';
import SplitSection from '../components/SplitSection';
import LogoContacto from '../svg/contact';
import LogoFacebook from '../svg/Logofacebook';
import LogoInstagram from '../svg/LogoInstagram';
import LogoMailContacto from '../svg/LogoMailContacto';
import LogoInstagramContacto from '../svg/LogoInstagramContacto';
import LogoFacebookContacto from '../svg/LogofacebookContacto';
import { Link } from 'gatsby';

export default class Contacto extends Component {
  render() {
    return (
      <>
        <Header />
        <div className="my-8"/>
        <SplitSection
          primarySlot={
            <div className="lg:pl-32 xl:pl-20">
              <h2 className="md:flex text-3xl hidden lg:text-5xl font-semibold">
                Habla con nosotros
              </h2>
              <h2 className="flex md:hidden text-3xl lg:text-5xl font-semibold">Contáctanos</h2>
              <div className="container flex flex-col ">
                <div className="flex items-center mt-10">
                  <div className="w-10 m-2">
                    <Link to="http://www.facebook.com/eufoniapp" target="blank">
                      <LogoFacebookContacto />
                    </Link>
                  </div>
                  <div className="ml-2 text-xl">
                    <Link to="http://www.facebook.com/eufoniapp" target="blank">
                      EufoniApp
                    </Link>
                  </div>
                </div>
                <div className="flex items-center mt-10">
                  <div className="w-10 m-2">
                    <Link to="http://www.instagram.com/Eufoniapp" target="blank">
                      <LogoInstagramContacto />
                    </Link>
                  </div>
                  <div className="ml-2 text-xl">
                    <Link to="http://www.instagram.com/Eufoniapp" target="blank">
                      @EufoniApp
                    </Link>
                  </div>
                </div>
                <div className="flex items-center mt-10">
                  <div className="w-10 m-2">
                    <a href="mailto:bitasone@gmail.com" target="blank">
                      <LogoMailContacto />
                    </a>
                  </div>
                  <div className="ml-2 text-xl ">
                    <a href="mailto:bitasone@gmail.com" target="blank">
                      BitAsOne@gmail.com
                    </a>
                  </div>
                </div>
              </div>
            </div>
          }
          secondarySlot={
            <div className="mx-auto md:w-4/5 ">
              <LogoContacto />
            </div>
          }
        />
        <Footer />
      </>
    );
  }
}

